<template>
  <div>
    <v-row class="px-4 product-row">
      <v-col md="12" class="px-0 py-0">
        <v-expansion-panels elevation="1" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5 class="my-0 px-0 py-0">Attachments</h5>
            </v-expansion-panel-header>

            <v-divider class="my-0"> </v-divider>

            <v-expansion-panel-content class="px-0 py-0">
              <Files
                class="mx-12"
                type-text="Supplier Attachments"
                type="supplier"
                :type-uuid="14"
              ></Files>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Files from "@/view/components/Files";

export default {
  name: "attactment",
  data() {
    return {
      panel: [0],
      // product: {
      // }
    };
  },
  components: {
    Files,
  },
};
</script>

<style scoped>
.th {
  font-size: 13px !important;
}

.product-row {
  margin: 0 !important;
}
</style>
